import React, { useContext, useEffect } from "react"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import SectionBox from "../components/SectionBox"
import SectionSeparator from "../components/SectionHorizontalSeparator"
import Anim from "../components/Anim"
import { getImage } from "gatsby-plugin-image"
import SectionBannerImage from "../components/SectionBanner"
import ContactForm from "../components/ContactForm/ContactForm"
import ContactSection from "../components/ContactSection/ContactSection"
import DescriptionPart from "../components/DescriptionPart"
import GlobalContext from "../components/GlobalContext/GlobalContext"

const localText = {
  title: {
    sr: "Kontakt",
    en: "Contact",
  },
  formTitle: {
    sr: "Pošalji upit",
    en: "Send enquiry",
  },
  formSubtitle: {
    sr: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum eleifend lorem quis neque finibus, vel efficitur nisl vestibulum. SR",
    en: "Please get in touch to discuss your needs and find right vessel for you.",
  },
  whyAsys: {
    sr: "",
    en: "<b>ASYS IS SERVING ADRIATIC YACHTING MARKET WITH EXPERTISE AND LOCAL INSIGHTS FOR DISTINGUISHED GLOBAL CLIENTELE.</b><br />Buying or chartering yacht requires understanding of actual conditions of the vessel, and we proud ourselves with in-depth local knowledge and a very thoughtful insight on yachts based in the Adriatic."
  },
}

const ContactPage = ({ pageContext, location }) => {
  const { lang: pLang, metaInfo, slug, otherLang, defLang, templateData } = pageContext
  const { lang, dispatch } = useContext(GlobalContext)

  const headerTitle = templateData.sections[0].items[0].locales.find(s => s.iso === lang).value
  const headerImage = templateData.sections[0].items[1].imageFile.publicURL

  const whyTitle = templateData.sections[1].items[0].locales.find(s => s.iso === lang).value
  const whySubtitle = templateData.sections[1].items[1].locales.find(s => s.iso === lang).value
  const whyDescription = templateData.sections[1].items[2].locales.find(s => s.iso === lang).value

  const formTitle = templateData.sections[2].items[0].locales.find(s => s.iso === lang).value
  const formSubtitle = templateData.sections[2].items[1].locales.find(s => s.iso === lang).value
  const formBtn = templateData.sections[2].items[2].locales.find(s => s.iso === lang).value
  useEffect(() => {
    dispatch({ type: "changeLanguage", payload: pLang })
  }, [pLang, dispatch])
  const data = useStaticQuery(graphql`
    query {
      contactFile: file(relativePath: { eq: "contacttopbanner.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
        publicURL
      }
      middleBannerFile: file(
        relativePath: { eq: "contactfooterbanner.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, aspectRatio: 2.22)
        }
        publicURL
      }
    }
  `)
  // const image = getImage(data.file)
  // const subtitle = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum eleifend lorem quis neque finibus, vel efficitur nisl vestibulum. `
  return (
    <Layout
      location={location}
      metaInfo={metaInfo}
      lang={lang}
      slug={slug}
      ohterLang={otherLang}
      defLang={defLang}
    >
      <SectionBannerImage
        noMargin
        title={headerTitle || localText.title[lang]}
        topBanner
        bgImage={headerImage || data.contactFile.publicURL}
        alt={`Contact us`}
      />
      <SectionBox>
        <Anim>
          <SectionSeparator bgColor="linear-gradient(181deg, black 0%, lightgrey 150%)" />
        </Anim>
      </SectionBox>
      <DescriptionPart>
        <h3 style={{marginTop:"20px"}} className="title">{ whyTitle || 'why ASYS?'}</h3>
        {whySubtitle && whyDescription && <div>
          <p><b>{whySubtitle}</b></p>
          <p>{whyDescription}</p>
        </div>}
        {/*<p dangerouslySetInnerHTML={{__html: localText.whyAsys[lang]}}/>*/}
      </DescriptionPart>
      <SectionBannerImage
        noMargin
        image={getImage(data.middleBannerFile.childImageSharp)}
        alt="Section baner image"
      />
      <ContactSection
        title={formTitle || localText.formTitle[lang]}
        subtitle={formSubtitle || localText.formSubtitle[lang]}
      >
        <ContactForm subtitle={formSubtitle} btnLabel={formBtn} withSubText lang={lang} />
      </ContactSection>
    </Layout>
  )
}

export default ContactPage
